
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer"
import Header from './components/Header'

function App() {
  return (
    <div>
      <Helmet>
          <title>The Glimpse Group</title>
          <meta
            name="description"
            content="The Glimpse Group, a Virtual and Augmented Reality platform company, comprised of multiple VR and AR software and services companies."
          />
          <meta
            name="keywords"
            content="virtual, augmented, reality, VR, AR, software"
          />
      </Helmet>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} /> 
        </Routes>
        <Footer />
      </Router>
    </div>
    
  );
}

export default App;
