import React from 'react'

function OurWork() {
    return (
        <div className=' bg-glimpse-blue/20' id='our_work' >
            <div className='bg-glimpse-blue/20 w-full flex justify-center' >
                <div className=' w-full max-w-5xl px-8 md:px-16 text-white flex flex-col py-10 w-full items-end justify-center' style={{ height: '450px'}} >
                    <h2 className='text-4xl mb-4 font-light text-right'>העבודות שלנו</h2>
                
                    <p className='text-right smallMobile:w-72 lg:w-96' dir='rtl'>
                    טכנולוגיות <span dir='lrt' className='not-italic'> AR/VR </span> ממציאות מחדש את הדרך שבה ארגונים ויחידים מתקשרים, עובדים ביחד ורואים את העולם. הפרויקטים שלנו, מבוססי טכנולוגיות ה-<span dir='lrt' className='not-italic'>XR</span>, מותאמים לצרכים של מגוון רחב של ענפים בתחומים שונים, והם מבוססים על האתגרים העסקיים שמעסיקים ענפים אלו. קבוצת <span dir='lrt' className='not-italic'> Glimpse </span> שמחה להציג את מבחר הפרויקטים שביצענו ללקוחותינו.
                    </p>
                    <a className="float-right rounded-md bg-glimpse-blue hover:text-zinc-300 text-zinc-100 mt-4 hover:bg-sky-600 pt-2 transition duration-700 transform hover:-translate-y-1 h-10 text-right" href="https://www.theglimpsegroup.com/case-studies" target="_blank" rel="noreferrer">
                        <p dir='rtl' className='px-2'> לצפייה בעבודות נבחרות </p>
                    </a>
                
                </div>  
            </div>
            
        </div>
    )
}

export default OurWork
