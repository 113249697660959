import Qreal from "../../assets/images/companies/qreal.png";
import Foretell from "../../assets/images/companies/foretell.png";
import IHG from "../../assets/images/companies/ihg.png";
import Spear from "../../assets/images/companies/logo_spear_xr.png";
import Brightline from "../../assets/images/companies/brightline_3.png";
import XRTerra_blue from "../../assets/images/companies/xrterra.png";

import Sector from "../../assets/images/companies/sector5digital.png";

const companies = [
  {
    id: 1,
    image: Qreal,
    link: "https://www.qreal.io/",
    alt: "QReal",
    description:
      "Lifelike AR and 3D models for branding, retail and food industries. QReal is creating the next generation of visual commerce.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  {
    id: 13,
    image: Sector,
    link: "https://www.sector5digital.com/",
    alt: "Sector 5",
    description:
      "S5D combines brilliant storytelling with emerging technology for industry leading organizations.",
    class: "hexagon__content hexagon__content-mb",
    invert: true,
  },
  {
    id: 2,
    image: Foretell,
    link: "https://foretellreality.com/",
    alt: "Foretell Reality",
    description:
      "VR coworking spaces for collaboration, soft skill development, interpersonal interactions, therapy and support.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  {
    id: 3,
    image: Spear,
    link: "http://spearxr.io/",
    alt: "Spear XR",
    description:
      "Enabling organizations to easily create, edit and deploy personalized and specialized AR experiences and presentations.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  {
    id: 12,
    image: XRTerra_blue,
    image2: XRTerra_blue,
    link: "https://www.xrterra.com/",
    alt: "XRTerra",
    description:
      "XR training and development courses for software engineers, graphic designers, 3D modelers, and artists.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  {
    id: 6,
    image: IHG,
    link: "https://immersivehealthgroup.com/",
    alt: "Immersive Health Group",
    description:
      "Leverages VR/AR technologies to increase access to care and educate clinicians, practitioners and patients.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  {
    id: 15,
    image: Brightline,
    link: "https://brightlineinteractive.com/",
    alt: "Brightline Interactive",
    description:
      "Provider of VR and AR integration, experiential training simulations, and immersive experiences for government and commercial clients.",
    class: "hexagon__content hexagon__content-mb",
    invert: false,
  },
  
];

export default companies;
