import React from 'react'
import allindustries from './data/allindustries'
import IndividualSolution from './IndividualSolution'
import Accordion from 'react-bootstrap/Accordion';
 
function IndustrySolutions() {
    return (
        <div>
            <div className=' bg-glimpse-blue flex justify-center'>
                <div className="flex flex-col justify-center items-center max-w-5xl w-full px-8 md:px-16">
                    <div className='w-full'>
                        <h1 className='py-3 text-white font-light text-4xl text-right' dir='rtl'> שירותי תוכנה ויישומי <span dir="ltr" className='not-italic'>VR/AR</span> לארגונים </h1>
                        <div className='text-zinc-200 flex justify-end'>
                            <h3 className='text-zinc-100' dir='rtl'> אנו עוזרים לארגונים לשלב שימוש בטכנולוגיות <span dir="ltr" className='not-italic'>XR</span> כדי להניע חדשנות ולהפיק את המירב מהיתרונות של טכנולוגיות אימרסיביות אלו. <br></br> אנו מחזיקים בידע וניסיון נצבר בעבודה מול מגוון רחב של סקטורים ומכאן היכולת לעזור לארגון להתחיל בקטן, בפשטות ולהתקדם במהירות. </h3>
                        </div>
                    </div>
                    <div className="my-5 w-full">
                        <Accordion dir="rtl">
                            <div className="flex flex-col bg-glimpse-blue">
                                {allindustries.map((item, index) => {
                                    return (
                                        <Accordion.Item eventKey={index} key={index} className='bg-glimpse-blue border-x-0 border-white/50'>
                                            <IndividualSolution item={item} index={index} /> 
                                        </Accordion.Item>
                                    )
                                })} 
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustrySolutions
