import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function IndividualSolution(props) {
    return (
        <>       
            <Accordion.Header className="motion-card flex py-0 m-2 hover:bg-glimpse-blue transform duration-500 hover:ease hover:scale-105"  >
                <div className="title-content flex items-center w-full">
                    <img src={props.item.hIcon} alt={props.item.name} style={{ height: '30px', width: '30px' }} />
                    <h3 className='flex items-center text-base text-white text-right mr-2 md:mr-20 text-xl md:text-2xl float-right' dir='rtl'>
                        {props.item.btnTitle}
                    </h3>
                </div>
            </Accordion.Header>
            <Accordion.Body className='pl-3 pb-3 pr-0 md:mx-28'>
                <div id={`test${props.index}`} className='mx-2'>
                    <div className='text-white text-right'>
                        {props.item.mainText}
                    </div>
                    <iframe 
                        width="300" 
                        height="230" 
                        src={props.item.video} 
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className='grow p-0 m-0 border-0'
                        

                    /> 
                    <div className='hover:scale-105 duration-300 flex items-end w-32'>
                        <a href={`https://theglimpsegroup.com/industry-solutions/${props.item.link}`} target="_blank" rel="noreferrer">
                            <p className='text-zinc-100 inline-block' dir='rtl'>
                            פרטים נוספים <i className="fas fa-arrow-left inline-block"> </i>
                            </p>
                        </a>
                    </div>
                    
                </div>
            </Accordion.Body>
        </>
    )
}

export default IndividualSolution
