import Snapchat from "../../assets/images/clients/snapchat_web.png"
import Temple from "../../assets/images/clients/temple.png"
import Dominos from "../../assets/images/clients/dominos.png"
import Unreal from "../../assets/images/clients/unreal.png"
import Colgate from "../../assets/images/clients/colgate.png"
import MagicLeap from "../../assets/images/clients/magic-leap.png"
import Allergan from "../../assets/images/clients/allergan.png"
import Fordham from "../../assets/images/clients/fordham.png"
import HTC from "../../assets/images/clients/htc.png"
import Intel from "../../assets/images/clients/intel.png"
import Bayer from "../../assets/images/clients/bayer.png"
import Mount from "../../assets/images/clients/mount.png"
import Verizon from "../../assets/images/clients/verizon.png"
import Swiss from "../../assets/images/clients/swiss.png"
import Montefiore from "../../assets/images/clients/montefiore.png"
import Uconn from "../../assets/images/clients/uconn.png"
import Bolle from "../../assets/images/clients/bolle.png"
import Pwc from "../../assets/images/clients/pwc.png"
import Chanel from "../../assets/images/clients/chanel.png"
import AmericanAirLine from "../../assets/images/clients/american-airline.png"
import Goodway from "../../assets/images/clients/goodway_web.png"
import D_ID from "../../assets/images/clients/d_id_web.png"
import Unity from "../../assets/images/clients/unity_web.png"
import NSU from "../../assets/images/clients/nsu_web.png"
import Boston_Med from "../../assets/images/clients/boston_med_web.png"
import Edstutia from "../../assets/images/clients/edstutia_web.png"
import Northstar from "../../assets/images/clients/northstar_web.png"
import Pico from "../../assets/images/clients/pico.png"
import HP from "../../assets/images/clients/hp.png"
import Meta from "../../assets/images/clients/meta.png"
import Google from "../../assets/images/clients/google.png"
import Microsoft from "../../assets/images/clients/microsoft.png"
import ATT from "../../assets/images/clients/attSized.png"
import Coke from "../../assets/images/clients/cokeSized.png"
import DOD from "../../assets/images/clients/dod.png"
import NPS from "../../assets/images/clients/nps.png"

const allclients = [
  {
    id: 160,
    image: Snapchat,
    alt: 'SnapChat',
    link: 'https://www.snapchat.com/',
    type: 'client'
  },
  {
    id: 165,
    image: Intel,
    alt: 'Intel',
    link: 'https://www.intel.com/content/www/us/en/homepage.html', 
    type: 'client'
  },
  {
    id: 110,
    image: Verizon,
    alt: 'Verizon',
    link: 'https://www.verizon.com/', 
    type: 'client'
  },
  {
    id: 115,
    image: Swiss,
    alt: 'Swiss Chalet',
    link: 'https://www.swisschalet.com/en.html', 
    type: 'client'
  },

  {
    id: 120,
    image: Montefiore,
    alt: 'Montefiore',
    link: 'https://www.montefiore.org/', 
    type: 'client'
  },
  {
    id: 125,
    image: Uconn,
    alt: 'University of Connecticut',
    link: 'https://uconn.edu/', 
    type: 'client'
  },
  {
    id: 130,
    image: Bolle,
    alt: 'Bolle',
    link: 'https://www.bolle.com/us/', 
    type: 'client'
  },{
    id: 135,
    image: Pwc,
    alt: 'PwC',
    link: 'https://www.pwc.com/', 
    type: 'client'
  },
  {
    id: 140,
    image: Chanel,
    alt: 'Chanel',
    link: 'https://www.chanel.com/us/',
    type: 'client' 
  }, {
    id: 145,
    image: AmericanAirLine,
    alt: 'American Air Line',
    link: 'https://www.aa.com/homePage.do', 
    type: 'client'
  },
  {
    id: 150,
    image: Bayer,
    alt: 'Bayer Global',
    link: 'https://www.bayer.com/en/', 
    type: 'client'
  }, {
    id: 155,
    image: Mount,
    alt: 'Mount Sinai',
    link: 'https://www.mountsinai.org/', 
    type: 'client'
  },
  
  {
    id: 170,
    image: HTC,
    alt: 'HTC',
    link: 'https://www.htc.com/us/', 
    type: 'partner'

  }, {
    id: 175,
    image: Colgate,
    alt: 'Colgate',
    link: 'https://www.colgate.com/en-us', 
    type: 'client'
  },

  {
    id: 180,
    image: Temple,
    alt: 'Temple University',
    link: 'https://www.temple.edu/', 
    type: 'client'
  }, {
    id: 185,
    image: Dominos,
    alt: 'Dominos Pizza',
    link: 'https://www.dominos.com/', 
    type: 'client'
  },

  {
    id: 190,
    image: Unreal,
    alt: 'Epic Games: Unreal Egine',
    link: 'https://www.unrealengine.com/en-US/', 
    type: 'partner'
  }, 
  {
    id: 195,
    image: MagicLeap,
    alt: 'Magic Leap',
    link: 'https://www.magicleap.com/en-us/', 
    type: 'partner'
  },

  {
    id: 200,
    image: Allergan,
    alt: 'Allergan',
    link: 'https://www.allergan.com/', 
    type: 'client'
  }, {
    id: 205,
    image: Fordham,
    alt: 'Fordham University',
    link: 'https://www.fordham.edu/', 
    type: 'client'
  },
  {
    id: 210,
    image: Goodway,
    alt: 'Goodway',
    link: 'https://www.goodway.com/', 
    type: 'client'
  }, {
    id: 215,
    image: D_ID,
    alt: 'D ID',
    link: 'https://www.d-id.com/', 
    type: 'partner'
  },
  {
    id: 220,
    image: Unity,
    alt: 'Unity',
    link: 'https://unity.com/', 
    type: 'partner'
  }, {
    id: 225,
    image: NSU,
    alt: 'NSU',
    link: 'https://www.nova.edu/', 
    type: 'client'
  },
  {
    id: 230,
    image: Boston_Med,
    alt: 'Boston Medical',
    link: 'https://www.bmc.org/', 
    type: 'client'
  }, {
    id: 235,
    image: Edstutia,
    alt: 'Edstutia',
    link: 'https://edstutia.com/', 
    type: 'client'
  },
  {
    id: 240,
    image: Northstar,
    alt: 'North-Star Care',
    link: 'https://northstarcare.com/', 
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 245,
    image: Pico,
    alt: 'Pico',
    link: 'https://www.picoxr.com/global/',  
    type: 'partner'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 250,
    image: HP,
    alt: 'HP',
    link: 'https://www.hp.com/us-en/home.html',  
    type: 'partner'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 255,
    image: Meta,
    alt: 'Meta',
    link: 'https://about.facebook.com/',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 260,
    image: Microsoft,
    alt: 'Microsoft',
    link: 'https://www.microsoft.com/en-us/',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 265,
    image: Google,
    alt: 'Google',
    link: 'https://about.google/?fg=1&utm_source=google-US&utm_medium=referral&utm_campaign=hp-header',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 270,
    image: ATT,
    alt: 'ATT',
    link: 'https://www.att.com/',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 275,
    image: Coke,
    alt: 'Coca-cola',
    link: 'https://www.coca-cola.com/',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 280,
    image: DOD,
    alt: 'Department of Defense',
    link: 'https://www.defense.gov//',  
    type: 'client'
  }, {
    // intentionally repeated to keep grid in order, remove when adding new partners
    id: 285,
    image: NPS,
    alt: 'National Parks Service',
    link: 'https://www.nps.gov/index.html',  
    type: 'client'
  },
];

export default allclients;