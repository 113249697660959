import React, { useState, useEffect } from "react";
// import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [org, setOrg] = useState();
    const [selectedInterest, setSelectedInterest] = useState("מתעניין ב:");
    const [message, setMessage] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [detailsToSend, setDetailsToSend] = useState()
    //const gaEventTracker = useAnalyticsEventTracker('Contact us');
  
    const sitekey = process.env.REACT_APP_RECAPTCHA_V3
    const recaptchaRef = React.createRef();

    function handleEmailChange(event){
        setEmail(event.target.value)
    }

    function handlePhoneChange(event){
      setPhone(event.target.value)
    }

    function handleSelectedInterestChange(event){
      setSelectedInterest(event.target.value)
    }
      
    function handleFirstChange(event){
        setFirstName(event.target.value)
    }
      
    function handleLastChange(event){
        setLastName(event.target.value)
    }
    
    function handleOrgChange(event){
        setOrg(event.target.value)
    }
      
    function handleMessageChange(event){
        setMessage(event.target.value)
    }

    useEffect(() => {
      if (email && firstName && lastName && org && phone){
        setSubmitDisabled(false)
      } else {
        setSubmitDisabled(true)
      }
    },[email, firstName, lastName, phone, org])
    
    function handleSubmit(event) {
      event.preventDefault();
      setDetailsToSend(event.target)
      console.log('submitting, before recaptcha')
      console.log('sitekey', sitekey)
      recaptchaRef.current.execute();
    }

    const onReCAPTCHAChange = (captchaCode) => {
      // If the reCAPTCHA code is null or undefined indicating that
      // the reCAPTCHA was expired then return early
      if(!captchaCode) {
        console.log('no captcha')
        return;
      }
      console.log('going to send email')
      // Else reCAPTCHA was executed successfully so proceed sending message
      emailjs.sendForm('service_d1x8ss7', 'template_mcf8zsn', detailsToSend, 'ZTQJioF16AnG3aZEk')
        .then((result) => {
            console.log(result.text);
            // gaEventTracker('contact_form_submitted')
        }, (error) => {
            console.log(error.text); 
        });
        setSubmitted(true)
        setEmail('')
        setPhone('')
        setFirstName('')
        setLastName('')
        setOrg('')
        setSelectedInterest(<p dir='rtl'>מתעניין ב:</p>)
        setMessage('')
      // Reset the reCAPTCHA so that it can be executed again if user 
      // submits another email.
      recaptchaRef.current.reset();
    }

    return (
      <div className='flex justify-center w-full bg-zinc-100' id="contact">
        <div className="max-w-5xl w-full px-8 md:px-16">
          <div className=' flex justify-center'>
            <div className='w-full'>
              <div className=' w-full contact flex flex-col items-center py-4'>
                  <div className="mb-3 justify-end w-full">
                    <h2 className='font-light text-gray-600 pb-clr my-2 text-4xl' dir='rtl'> צרו קשר ונשמח לחזור בהקדם </h2>
                  </div>

                  <div className="w-full">
                    <form
                        name="insightly_form"
                        data-form-id="8602"
                        id="contact-form"
                        encType="text/plain"
                        onSubmit={ handleSubmit }
                    >
                    <div className='flex flex-col items-center content-center my-4'>
                      <div className={`w-full flex flex-col`}>
                        <div className='flex border-gray-500 border-b w-full' dir='rtl'>
                          <div dir='rtl' className='w-1/2'>
                            <label htmlFor='firstName' className='visuallyHidden'>First Name:</label>
                            <input
                              id="insightly_FIRST_NAME"
                              name="firstName"
                              dir='rtl'
                              type="text"
                              value={firstName} 
                              placeholder='*שם פרטי'
                              onChange={handleFirstChange}
                              autoComplete="off"
                              className='py-3 pr-3 bg-zinc-100 w-full text-gray-500 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                              maxLength="30"
                              required 
                            />
                          </div>
                          <div dir='rtl' className='w-1/2'>
                            <label htmlFor='lastName' className='visuallyHidden'>Last Name:</label>
                            <input
                              id="insightly_LAST_NAME"
                              name="lastName"
                              dir='rtl'
                              type="text"
                              value={lastName} 
                              placeholder='*שם משפחה'
                              onChange={handleLastChange}
                              autoComplete="off"
                              className='py-3 pr-3 bg-zinc-100 w-full text-gray-500 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                              maxLength="30"
                              required
                            />
                          </div>
                        </div>
                        <div dir='rtl'>
                          <label htmlFor='email' className='visuallyHidden'>Email:</label>
                          <input
                            id="insightly_EMAIL_ADDRESS"
                            name="email"
                            type="text"
                            dir='rtl'
                            value={email} 
                            onChange={handleEmailChange}
                            placeholder='*אימייל'
                            className='p-3 bg-zinc-100 w-full text-gray-500 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                            autoComplete="off"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$"
                            maxLength='75'
                            required
                          />
                        </div>
                        <div className='border-y border-gray-500' dir='rtl'>
                          <label htmlFor='phone' className='visuallyHidden'>Phone:</label>
                          <input
                            id="phone"
                            name="phone"
                            type="tel"
                            dir='rtl'
                            value={ phone } 
                            onChange={ handlePhoneChange }
                            placeholder='*טלפון'
                            className='p-3 bg-zinc-100 text-gray-500 w-full focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10' 
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div dir='rtl'>
                          <label htmlFor='org' className='visuallyHidden'>Organization:</label>
                          <input
                            id="insightly_ORGANISATION_NAME"
                            name="org"
                            type="text"
                            dir='rtl'
                            value={org} 
                            onChange={handleOrgChange}
                            autoComplete="off"
                            className='p-3 bg-zinc-100 w-full text-gray-500 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                            placeholder='*שם החברה'
                            maxLength="75"
                            required
                          />
                        </div>
                        <div className='border-y border-gray-500' dir='rtl'>
                          <label htmlFor='interest' className='visuallyHidden'>Interest:</label>
                          <select
                            id="insightly_INTEREST"
                            name="interest"
                            type="text"
                            value={ selectedInterest } 
                            onChange={ handleSelectedInterestChange }
                            dir='rtl'
                            className='bg-zinc-100 w-full text-gray-400 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                            autoComplete="off"
                          >
                            <option disabled className='flex justify-between' dir='rtl' value="מתעניין ב:"> מתעניין ב: &nbsp; &nbsp; &darr;</option>
                            <option dir='rtl' value="אקו-סיסטם של Glimpse"> אקו-סיסטם של Glimpse </option>
                            <option dir='rtl' value="פתרונות XR"> פתרונות XR </option>
                          </select>
                        </div>
                        <div className='border-b border-gray-500' dir='rtl'>
                          <label htmlFor='message' className='visuallyHidden'>Message:</label>
                          <textarea
                            id="insightly_Message"
                            name="message"
                            type="text"
                            dir='rtl'
                            rows={8}
                            value={message} 
                            onChange={handleMessageChange}
                            placeholder="הודעה"
                            className='p-3 bg-zinc-100 -mb-1 text-gray-500 w-full h-28 focus:outline-none focus:border-r focus:border-glimpse-blue focus:bg-glimpse-blue/10'
                            autoComplete="off"
                            maxLength="300"
                          />
                        </div>
                        
                      </div>
                    
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={sitekey}
                        onChange={onReCAPTCHAChange}
                        className='z-50'
                      />
                      
                      <div className='flex-col content-center w-full'>
                        <div className='flex justify-center'>
                          <button
                            className="btn my-4 bg-glimpse-blue py-3 hover:bg-sky-600 text-white transform duration-500 hover:ease px-2 py-1 rounded-lg w-full "
                            type="submit"
                            disabled={submitDisabled}
                          >
                            שלחו פרטים
                          </button>
                          

                        </div>
                        
                        { !submitted ?
                          <p className='text-center mt-2 text-sm'> שדה חובה* </p>
                          :
                          <p className='text-center text-green-700 mt-2 text-lg bg-green-200 rounded-lg p-1' dir='rtl'> תודה! הודעתך נשלחה. ניצור עמך קשר בקרוב </p>
                        }
                        
                      </div>
                      
                    </div>
                  </form>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    )
}

export default ContactForm
