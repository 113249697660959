import React from 'react'
import Carousel from 'react-multi-carousel';
import allcompanies from "./data/allcompanies";

function OurCompanies() {
    
    const responsive = {
        xlDesktop: {
            breakpoint: { max: 3000, min: 1500 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1500, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 800 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet_mini: {
            breakpoint: { max: 800, min: 600 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
          },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <div className='flex justify-center bg-zinc-100'>
            <div className='flex flex-col w-full max-w-5xl px-8 md:px-16'>
                <h1 className='text-4xl text-gray-500 mb-3 mt-6 text-right' dir='rtl'>אקו-סיסטם ייחודי של חברות-בת בתחום ה  <span dir='ltr' className='not-italic'>XR</span></h1>
                <p dir='rtl' className='font-light text-gray-500 my-2 text-right'>
                קבוצת <span dir='ltr' className='not-italic'>Glimpse</span> (נאסד"ק: <span dir='ltr' className='not-italic'>VRAR</span>) הנה חברה שפועלת לטיפוח סטרטאפים בתעשיית המציאות המדומה <span dir='ltr' className='not-italic'>(VR)</span> והמציאות הרבודה <span dir='ltr' className='not-italic'>(AR)</span>. <br></br>
                המודל העסקי הייחודי שלנו מפשט את האתגרים הרבים העומדים בפני סטרטאפים ובו זמנית מספק למשקיעים הזדמנות להשקיע ישירות בתעשיית ה-<span dir='ltr' className='not-italic'>XR</span> המתפתחת. קבוצת <span dir='ltr' className='not-italic'>Glimpse</span> מממנת, מטפחת ומנהלת את הפעילות העסקית של חברות הבת תוך מתן רשת חזקה של קשרים מקצועיים. בדרך זו קבוצת <span dir='ltr' className='not-italic'>Glimpse</span> מאפשרת לסטרטאפים לפנות את הזמן והמשאבים שלהם לטובת פיתוח המוצר והחדירה אל השוק. <br></br><br></br> גאים להציג את חברות הבת שלנו בקבוצה:
                </p>
                <div>   
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        arrows={true} 
                        renderButtonGroupOutside={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={6000}
                        keyBoardControl={true}
                        //customTransition="all 1.5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        //removeArrowOnDeviceType={["mobile"]}
                        //deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px w-32"
                        >
                        {allcompanies.map((item, index) => (
                            <div className='h-32 w-full flex justify-center' key={ index }>
                                <div className="slide h-32 w-32">
                                    <a href={item.link} target="_blank" rel="noreferrer">
                                        <img src={item.image} alt={item.alt} className='h-32 w-32' />
                                    </a>
                                </div>          
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className='flex justify-end'>
                    <a
                        className={`btn whitespace-nowrap bg-glimpse-blue hover:bg-sky-600 px-2 rounded-lg pt-2 pb-2 text-white transition duration-700 transform hover:-translate-y-1 mb-4`}
                        href="#contact"
                        dir='rtl'
                        >
                        אנו חברת <span dir='ltr' className='not-italic'>XR</span>, נשמח לקבל עוד פרטים     
                    </a>
                </div>
                
            </div>
        </div>
    )
}

export default OurCompanies
