import React from 'react'
import { AppBar, Toolbar } from '@mui/material';
import logo from "../assets/images/glimpse_isreal_logo.png";
import enFlag from "../assets/images/en_flag.png";
import ilFlag from "../assets/images/il_flag.png";
import Dropdown from 'react-bootstrap/Dropdown';

function Header() {

    window.onscroll = function() {scroll()};

    function scroll(){
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById("logo").style.width = "6.4rem";
        document.getElementById("logo").style.height = "4rem";
        } else {
        document.getElementById("logo").style.width = "180px";
        document.getElementById("logo").style.height = "112px";
        } 
    }
    
    return (
        <div>
            <AppBar position="fixed" color="default" className='p-0 z-50 flex justify-center bg-white' style={{ zIndex: 999 }}>
                <Toolbar variant="regular" className='flex justify-center' style={{ padding: "0 1em 0 0" }}>
                    <div className='w-full flex justify-center'>
                        <div className="max-w-5xl w-full">
                            <div className='flex items-center justify-between w-full p-0' >
                                <a href='/' className='my-1'>
                                    <img alt="Glimpse Group Israel Logo" id='logo' className='transform duration-500 ease h-28 w-auto' src={logo} />
                                </a>
                                <div className='flex items-center'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" className='flex h-10 w-14 mr-2 justify-center items-center border-none hover:bg-transparent focus:bg-zinc-100'>
                                            <img src={ ilFlag } alt='Hebrew Language' className='h-10 w-10' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ minWidth: '3rem' }}>
                                            <Dropdown.Item href='https://www.theglimpsegroup.com/'><img src={ enFlag } alt='English Language' className='h-10 w-10' /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <a
                                        className={` h-8 w-20 text-center pt-1 whitespace-nowrap bg-glimpse-blue hover:bg-sky-600 rounded-lg text-white transition duration-700 transform hover:-translate-y-1 mr-4`}
                                        style={{ margin: '0' }}
                                        href="#contact"
                                        >
                                        צרו קשר
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Header
