import React from "react"
import allclients from "./data/allclients";
import Carousel from 'react-multi-carousel';
import '../App.css'

export default function Clients() {

  const responsive = {
    xlDesktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 1500, min: 1150 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1150, min: 800 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet_mini: {
      breakpoint: { max: 800, min: 600 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className='flex justify-center'>
      <div className='max-w-5xl w-full px-8 md:px-16'>
        <div className="flex mt-6 w-full">
            <h2 className="pb-clr text-gray-600 font-light w-full text-4xl text-right" dir='rtl'> השותפים שלנו </h2>
        </div>
        <div>
          <Carousel
            swipeable={true}
            draggable={true}
            arrows={true} 
            renderButtonGroupOutside={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={7000}
            keyBoardControl={true}
            //customTransition="all 1.5"
            transitionDuration={500}
            containerClass="carousel-container"
            //removeArrowOnDeviceType={["mobile"]}
            //deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px w-32"
          >
            {allclients.map((item, idx) => (
              item.type === 'partner' &&
              <div className='h-32 w-full flex justify-center' key={item.id}>
                <div className="slide h-32 w-32">
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <img src={item.image} alt={item.alt} className='h-32 w-32' />
                    </a>
                </div>          
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}