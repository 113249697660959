import React, { useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
// import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import whitePaperBackground from '../assets/whitepaper/whitepaper_graphic_1.png'
import header from '../assets/email/header.png'
import footer from '../assets/email/footer.png'
import ecoBack from '../assets/backgrounds/ecosystem_clear_crop.png'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import * as ga from '../lib/ga'

function WhitepagesBanner() {
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [submitted, setSubmitted] = useState(false); 
    const [showSubmitted, setShowSubmitted] = useState(false);
    const toggleShowSubmitted = () => setShowSubmitted(!showSubmitted);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [count, setCount ] = useState(0) 
    // automatic signup for newsletter?
    const signup = false;
    // signup for whitepages
    const whitepages = true;
    // const router = useRouter()

    // google analytics event
    // function whitepaperSignupGA() {
    //     ga.event({
    //         action: "Whitepaper_Israel",
    //         category: "Whitepaper",
    //         label: "Whitepaper Sign Up (Banner)",
    //     })
    // }

    // function downloadPDF (){
    //     // using Java Script method to get PDF file
    //     fetch('/Glimpse_Group_White_Paper.pdf').then(response => {
    //         response.blob().then(blob => {
    //             // Creating new object of PDF file
    //             const fileURL = window.URL.createObjectURL(blob);
            
    //             // Setting various property values
    //             let alink = document.createElement('a');
    //             alink.href = fileURL;
    //             alink.download = 'Glimpse_White_Paper.pdf';
    //             alink.click();
               
    //         })
    //     })
    // }

    // function sendEmail (email, bod){
    //     // using Java Script method to get PDF file
    //     fetch(`/api/send-email/white-paper?email=${ email }&firstName=${ firstName }`)
    //     .then(response => console.log(response.json()))
    // }

    // function handleEmailChange(event){
    //     setEmail(event.target.value.toLowerCase())
    // }
    
    // function handleFirstChange(event){
    //     setFirstName(event.target.value)
    // }

    // const handleSubmit = async (event) => {
    //     setIsSubmitting(true)
    //     event.preventDefault();
    //     fetch(`/api/userEmail/${ event.target.email.value }`,{
    //         method: "GET",
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //     .then(response => response.json())
       
    //     .then(response => response.userID !== 0
    //         ? fetch(`/api/user/${response.userID}`, {
    //             method: "PUT",
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },

    //             //make sure to serialize your JSON body
    //             body: JSON.stringify({
    //                 id: response.userID.userID,
    //                 tags: response.userID.tags,
    //                 first: event.target.firstName.value,
    //                 email: event.target.email.value,
    //                 signup : signup,
    //                 whitepages: whitepages
    //             })
    //         })
    //         .then( (response) => { 
    //             sendEmail(email, firstName)
    //             // whitepaperSignupGA()
    //             setSubmitted(true)
    //             setEmail("")
    //             setFirstName("")
    //             toggleShowSubmitted()
    //             downloadPDF()
    //         })
    //         : 
    //         fetch("/api/user", {
    //             method: "POST",
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },

    //             //make sure to serialize your JSON body
    //             body: JSON.stringify({
    //                 first: event.target.firstName.value,
    //                 email: event.target.email.value,
    //                 signup : signup,
    //                 whitepages: whitepages
    //             })
    //         })
    //         .then( (response) => { 
    //             setSubmitted(true)
    //             sendEmail(email, firstName)
    //             // whitepaperSignupGA()
    //             setEmail("")
    //             setFirstName("")
    //             toggleShowSubmitted()
    //             downloadPDF()
    //         })
    //     )
    //     setIsSubmitting(false)
    // }

    return (
        <div>
            <div className='bg-glimpse-slate flex relative overflow-hidden justify-center w-full'>
                <img className='block object-cover absolute z-0 right-0 w-auto opacity-10 overflow-hidden' src={ ecoBack } />
                <div className='py-4 justify-center flex relative max-w-5xl px-8 md:px-16 w-full'>
                    <img className={`w-auto h-80 hidden lg:block`} src={ whitePaperBackground } />
                    <div className='flex flex-col justify-center items-center py-4 lg:mx-0'>
                        <div className='flex flex-col items-end'>
                            <div className="mr-5">
                                {/* <p className='text-white text-center mb-2 mt-6 text-xl'>Download the white paper.</p> */}
                                {/* <form
                                    name="whitepages-form"
                                    id="whitepages-form"
                                    encType="text/plain"
                                    onSubmit={ handleSubmit }
                                >
                                    <div className='flex flex-col items-center content-center my-1'>
                                        
                                        <div>
                                            <div className={`flex justify-around w-full breakGrid:flex-col`}>
                                                <div className={`mx-4`} id='userInfoBlock'>
                                                    <div className='my-2 flex flex justify-end items-center'>
                                                        <label htmlFor='email' className='visuallyHidden'>Email Address</label>
                                                        <input
                                                            id="insightly_EMAIL_ADDRESS"
                                                            name="email"
                                                            type="text"
                                                            value={ email } 
                                                            placeholder='Email*'
                                                            onChange={ handleEmailChange }
                                                            className='p-2 rounded-md w-64 bg-zinc-100 text-sm text-gray-500'
                                                            autoComplete="off"
                                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$"
                                                            maxLength="75"
                                                            aria-label="Email"
                                                            required
                                                        />
                                                    </div>
                                                    <div className='my-2 flex justify-end items-center'>
                                                        <label htmlFor='firstName' className='visuallyHidden'>First Name</label>
                                                        <input
                                                            id="insightly_FIRST_NAME"
                                                            name="firstName"
                                                            type="text"
                                                            value={ firstName } 
                                                            onChange={ handleFirstChange }
                                                            placeholder='First Name*'
                                                            className='p-2 text-sm rounded-md w-64 bg-zinc-100 text-gray-500'  
                                                            autoComplete="off"
                                                            pattern="[a-zA-Z ,.'-]+$"
                                                            maxLength="30"
                                                            aria-label="First Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        
                                            <div className='flex-col content-center w-full'>
                                                <p className='text-center text-white mt-2 text-sm'> שדה חובה* </p>
                                            </div>
                                            <div className='justify-center items-center flex mt-3'>
                                                <button 
                                                    type="submit" 
                                                    form='whitepages-form' 
                                                    name='submit_whitepaper'
                                                    id='submit_whitepaper'
                                                    className="rounded-sm transition duration-700 ease-in-out bg-glimpse-blue hover:bg-sky-600 p-2.5 text-white whitespace-nowrap"
                                                    aria-label="Download Now"
                                                    disabled={ isSubmitting }
                                                    dir='rtl'
                                                >
                                                    להורדת הקובץ
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                            <div className='text-white w-10/12 sm:w-104 '>
                                <p className=' text-3xl font-light mb-3' dir='rtl'> חדש! כל הסטטיסטיקות ו-<span dir='ltr'>ROI</span> בשימוש בטכנולוגיות <span dir='ltr'>VR</span> ו- <span dir='ltr'>AR</span> בתחומי: שיווק, למידה, ויזואליזציה, סושיאל ושיתוף פעולה מרוכזים בקובץ אחד! </p>
                                {/* <p className='text-xl text-white pb-2' >Download the white paper to access:</p>
                                <ul className='list-disc pl-8 text-white'>
                                    <li>Specific and quantifiable benefits of using VR and AR</li>
                                    <li>Insights for Marketing, Learning, Visualization, and Social & Collaboration use cases</li>
                                    <li>Innovative examples of VR and AR deployment across sectors</li>
                                </ul> */}
                            </div>
                            <div>
                            <a 
                                type="submit" 
                                form='whitepages-form' 
                                name='submit_whitepaper'
                                id='submit_whitepaper'
                                className="rounded-sm transition duration-700 ease-in-out bg-glimpse-blue hover:bg-sky-600 p-2.5 text-white whitespace-nowrap"
                                aria-label="Download Now"
                                target='_blank'
                                rel='noreferrer'
                                dir='rtl'
                                href='https://docs.google.com/forms/d/e/1FAIpQLScGh8lQtV_bq7HelK6EeSdXuTFgupfWLsCSj-1xfKdNsWdTUA/viewform?usp=send_form'
                            >
                                להורדת הקובץ
                            </a>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            { submitted &&
                <div className='w-screen h-screen fixed top-1/3 z-100 flex justify-center'>
                    <Toast show={ showSubmitted } onClose={toggleShowSubmitted} position='top-center' className='h-min w-5/6 md:w-1/2 bg-glimpse-midnight p-3 flex transform duration-700 mt-4'>
                        <div className='flex justify-center items-center relative'>
                            <div className='flex flex-col px-4 text-justify text-white my-10 text-lg font-light'>
                                <p className='py-3'>Thank you. Please check your recent downloads for your exclusive copy of our industry white paper.</p> 
                                <p> We have also emailed a link to your download to the email address provided</p>
                            </div>
                            <p onClick={toggleShowSubmitted} className='text-gray-100 hover:text-gray-300 transform duration-700 cursor-pointer absolute right-0 top-0 text-xl'><FontAwesomeIcon icon={faXmark} className='h-6 w-6' /></p>
                        </div>  
                    </Toast>
                </div>
            }
        </div>
    )
}

export default WhitepagesBanner 
