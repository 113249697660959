import React from 'react';
import Logo from '../assets/images/glimpsewhite-alt.png'
import facebook from "../assets/icons/facebook_logo.png"

function Footer () {
    return (
        <footer className='text-gray-100 pt-3 px-3 flex justify-center' style={{ backgroundColor: '#3c9cd4', fontFamily: 'Montserrat' }}>
            <div>    
                <div className='max-w-5xl px-8'>
                    <div className='flex justify-center lg:justify-start'>
                        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 justify-start items-center lg:items-start ">
                            <div className="flex lg:flex-col w-56 lg:ml-8 items-center h-full">
                                <a className=" footer-logo w-56 lg:w-40 flex justify-center" href="https://www.theglimpsegroup.com/">
                                    <img src={ Logo } className='margin-0' alt="The Glimpse Group" />
                                </a>
                            </div>
                            <div className='flex flex-col'>
                                <div className='w-full lg:w-11/12 xl:w-9/12'>
                                    <h2 className='text-2xl font-semibold'>Unlocking the Benefits of Virtual Reality & Augmented Reality</h2>
                                    <p className='my-3'>The Glimpse Group <a href='https://ir.theglimpsegroup.com/' target="_blank" rel="noreferrer" className='transform duration-700 ease-in-out hover:text-sky-700 hover:-translate-y-1'>(Nasdaq: VRAR)</a> is an immersive technology platform company, comprised of a diverse ecosystem of companies providing enterprise-focused AR and VR software and services accross sectors</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className='flex lg:justify-start mt-4'>
                        <div className='flex flex-col-reverse lg:flex-row items-start lg:items-end gap-4 lg:gap-8 text-sm'>
                            <div className='flex flex-col w-56'>
                                <div className='flex justify-start lg:justify-center gap-6'>
                                    <a href='https://www.theglimpsegroup.com/faq' className='transform duration-300 hover:ease text-white hover:-translate-y-1'>
                                        FAQ 
                                    </a>
                                    <a href="https://www.theglimpsegroup.com/privacy" className='transform duration-300 hover:ease text-white hover:-translate-y-1'>
                                        Privacy
                                    </a>
                                </div>
                                <p className='w-56 text-2xs lg:text-center leading-none py-1'>Protected by reCAPTCHA and the Google <br></br> <a href="https://policies.google.com/privacy"> Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                            </div>
                            <div className='flex flex-col md:flex-row gap-6 lg:gap-16'>
                                <div className='flex flex-col-reverse lg:flex-col justify-end'>
                                    <div>
                                        <a href="mailto:hellp@glimpsegroup.co.il" className='flex items-center transform text-white duration-300 hover:ease hover:-translate-y-1 w-64 -mb-0.5'>
                                            <i className="far fa-envelope mr-2"></i> 
                                            <p>hello@glimpsegroup.co.il</p>
                                        </a>
                                    </div>
                                    <div className="social-menu flex items-end">
                                        <a href="https://www.facebook.com/GlimpseIsrael" target="blank" className=' flex mb-1 items-end justify-center transform duration-300 hover:ease hover:-translate-y-1 hover:text-zinc-100' >
                                            <img src={facebook} alt='Facebook' className='h-4 w-4' />
                                        </a>
                                        <a href="https://www.linkedin.com/company/glimpse-israel/" target="blank" className=' flex -mb-1 items-end mx-3 justify-center transform duration-300 hover:ease hover:-translate-y-1 hover:text-zinc-100'>
                                            <i className="fab fa-linkedin-in text-lg hover:text-zinc-100" />
                                        </a>
                                    </div>
                                </div>
                                <div className='flex flex-col -mb-0.5'>
                                    <p className='pb-0.5'>The Glimpse Group Offices:</p>
                                    <p className='sm:whitespace-nowrap'>HQ - New York, US | Australia | Turkey | England | Israel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div>
                    <p className='w-full text-center mt-3 m-2 text-zinc-200 text-sm'>&copy; The Glimpse Group 2022</p>
                </div>
            </div>
            
        </footer>
      
    )
  };
  
  export default Footer;