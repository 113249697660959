import React from 'react'
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import VideoJS from "../components/VideoJS"
import Clients from "../components/Clients";
import IndustrySolutions from '../components/IndustrySolutions'
import OurCompanies from '../components/OurCompanies'
import Partners from '../components/Partners'
import ContactForm from '../components/ContactForm'
import OurWork from "../components/OurWork"
import Bios from "../components/Bios"
import WhitepagesBanner from '../components/WhitepaperBanner';

function HomePage() {
    
    return (
        <div className='relative mt-24'>
            <Helmet>
                <title>The Glimpse Group | VR and AR Enterprise Solutions</title>
                <meta
                name="description"
                content="The Glimpse Group is a Virtual Reality ( VR ) & Augmented Reality ( AR ) Platform Company comprised of multiple subsidiary companies offering VR and AR Solutions for enterprise"
                />
            </Helmet>
            <section className="home-content">
                <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                zIndex={99}
                height="91.45vh"
                width="100%"
                color="white"
                >
                <div className='py-4 w-full flex justify-center'>
                    <div className='max-w-5xl px-8 md:px-16'>
                        <h1 className='text-right font-semibold my-2 text-2xl md:text-4xl lg:text-5xl' dir='rtl'> 
                         השער ליישום טכנולוגיות מציאות מדומה ומציאות רבודה <span dir='ltr' className='not-italic'> (VR/AR) </span>
                        </h1>
                        <h2 dir='rtl' className='text-xl mt-4 md:text-3xl lg:text-4xl'>
                        קבוצת
                        <span dir='ltr' className='not-italic'> Glimpse </span>(נאסד"ק:<span dir='ltr' className='not-italic'>VRAR </span><span dir='rtl' className='not-italic'>) </span>  
                        הנה חברת פלטפורמה מבוססת טכנולוגיות 
                        <span dir='ltr' className='not-italic'> XR </span>
                        המורכבת מאקו-סיסטם מגוון של חברות-בת המספקות יישומים ותוכנות
                        <span dir='ltr' className='not-italic'> VR/AR </span>
                        לארגונים בענפים השונים בתעשייה 
                        </h2>
                    </div>
                </div>      
                </Box>
                <VideoJS />
            </section>
            <Clients />
            <IndustrySolutions />
            <WhitepagesBanner />
            <OurCompanies />
            <OurWork />
{/*            <Bios />*/}
            <ContactForm />
            <Partners />
        </div>
    )
}

export default HomePage
